import axios from 'axios';

// generate unique id using v4
const uuidv4 = () =>
	'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function ( c ) {
		var r = ( Math.random() * 16 ) | 0,
			v = c == 'x' ? r : ( r & 0x3 ) | 0x8;
		return v.toString( 16 );
	} );

const requestInput = document.getElementById( 'request' );

const form = document.getElementById( 'form' );

/**
 * Hit API to
 */
const handleSubmit = () => {
	// hide error first
	const err = document.getElementById( 'error' );
	err.classList.add( 'is-hidden' );
	err.innerText = '';

	const request = requestInput.value.trim();

	// form validation
	if (
		request.length < 150 ||
		request.indexOf( 'BEGIN-REQUEST' ) == -1 ||
		request.indexOf( 'END-REQUEST' ) == -1
	) {
		err.classList.remove( 'is-hidden' );
		err.innerText =
			'Manual activation requests must be composed of at least 150 characters and contain both BEGIN-REQUEST and END-REQUEST. Please ensure that the text you are providing is a manual activation request rather than your activation key.';
		return null;
	}

	const submit = document.getElementById( 'submit' );
	submit.classList.add( 'is-hidden' );

	const spinner = document.getElementById( 'spinner' );
	spinner.classList.remove( 'is-hidden' );

	// generate unique id for API request
	const requestId = uuidv4();
	document.getElementById( 'requestId' ).innerText = requestId;
	document
		.getElementById( 'requestIdWrapper' )
		.classList.remove( 'is-hidden' );

	axios
		.post(
			'https://prod-06.uksouth.logic.azure.com:443/workflows/35117120d7ad44f3af804ee8d3c71e59/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ICNvrYmrcslfJJCnnm2YkKKZrFt4FiWeum9z5FCBJ5M',
			{
				RequestBase64: request,
				RequestID: requestId,
			}
		)
		.then( ( { data } ) => {
			if ( data.ActivationErrorMessage ) {
				// show error message if API returns error
				err.classList.remove( 'is-hidden' );
				err.innerText = data.ActivationErrorMessage;
				submit.classList.remove( 'is-hidden' );
			} else {
				const filename = `${ requestId }.bin`;
				// add download uri to link so it downloads to browser
				const downloadLink = document.getElementById(
					'downloadButton'
				);

				document.getElementById( 'downloadSize' ).innerText =
					data.ExpectedFileSize / 1000;

				downloadLink.href = data.SecureLicenseURL;
				downloadLink.download = filename;

				downloadLink.addEventListener( 'click', () => {
					document.getElementById(
						'downloadStep'
					).style.opacity = 0.3;
				} );

				document.getElementById( 'activateStep' ).style.opacity = 0.3;

				document
					.getElementById( 'success' )
					.classList.remove( 'is-hidden' );
				form.classList.add( 'is-hidden' );
			}
		} )
		.catch( () => {
			/**
			 * Catch error and set error message
			 */
			submit.classList.remove( 'is-hidden' );
			err.classList.remove( 'is-hidden' );
			err.innerHTML = `Sorry there was an issue with this request, please try again. If the issue persists please contact <a href="https://support.squaredup.com/hc/en-us/requests/new">support@squaredup.com</a>.`;
			submit.classList.remove( 'is-hidden' );
		} )
		.then( () => {
			// always executed
			spinner.classList.add( 'is-hidden' );
		} );
};

form.addEventListener( 'submit', ( e ) => {
	e.preventDefault();
	handleSubmit();
} );
